import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2024/sctetechexpo/SCTE-24_events-desktop-hero.png';
import mobileBanner from '../../images/events/2024/sctetechexpo/SCTE-24_events-mobile-hero.png';
import ogimage from '../../images/events/2024/sctetechexpo/SCTE-24_events-og-image.png';

const SCTETechExpo2024 = () => {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='SCTE TechExpo 2024 - Witbe' isEvents>
            <SEO
                title='Visit Witbe at SCTE Cable TechExpo 2024 in Atlanta: 24-26 Sep'
                ogDescription='Visit Witbe at Booth 1612 in Atlanta for the SCTE TechExpo 24! See a live demo of our Ad Monitoring and Matching technology for video service providers.'
                description='Book a live demo today of our Remote Eye Controller technology for video service providers and new AI boosted testing updates!'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={14} banner={mobileBanner} /> : <DesktopEventPage id={14} banner={banner} />}
        </Layout>
    );
};

export default SCTETechExpo2024;
